import React from "react";
import "../../../App.css";
import "./Schools.css";
import { Card } from "react-bootstrap";

function SchoolsPage() {
  return (
    <div className="schools">
      <div className="container">
        <div className="schools_text">
          <h1>Schools</h1>
          <hr />
          <div className="row">
            <p style={{fontSize: '20px'}}>
              Teachers are on the front lines of recognizing and addressing the
              mental health of their students. Untreated children unduly tax
              school systems that lack the resources to intervene. Schools
              currently provide more than 50% of the therapeutic care this
              segment receives through contracts with local providers. They are
              under resourced in the best of times. The heightened anxiety and
              distance learning teaching environments due to Covid-19 are
              creating heightened demand for digital solutions.
            </p>
            <div className="col-md-6 col-sm-12">
              <div className="parents_img">
                <img
                  className="d-block w-100"
                  style={{ borderRadius: "15px" }}
                  src="images/struggling.jpg"
                  alt="Practice Naming Your Emotions"
                />
                <p className="text-center" style={{fontSize: '20px'}}>
                  Ready to learn more about YouROK?
                  <a
                    style={{ fontSize: 20 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
                  >
                    {" Request a demo"}
                  </a>
                </p>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="parents_txt">
                <Card style={{ border: "none", marginLeft: "25px" }}>
                  <p style={{fontSize: '20px'}}>
                    YouROK’s engaging app K’Bro captures useful insights into 
                    student well-being but more importantly provides low cost 
                    interventions to help students improve their emotional resiliency. 
                    YouROK’s self-care interventions reinforce social emotional literacy 
                    curriculum while identifying who needs help beyond classroom instruction. 
                    Student care and support teams can easily view student Am I OK? index scores, 
                    access synthesized results, and get real time, targeted insights into 
                    individual students as well as view school and district-wide trends. 
                    Data collected is always confidential and secure, fully complying with 
                    HIPAA and FERPA standards.
                  </p>
                </Card>
              </div>
            </div>
          </div>
          <blockquote>
            <div
              className="quote"
              style={{ borderLeft: "2.5px solid green", padding: "5px" }}
            >
              <p style={{fontSize: '20px'}}>
                "I have just felt so much better since downloading this game and
                I feel every middle schooler/ high schooler could use this
                game!"
              </p>
              <cite style={{fontSize: '20px'}}>15 year old user</cite>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default SchoolsPage;
