import React from "react";
import "./Footer.css";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer style={{ justifyContent: "space-around" }}>
      <div style={{ fontSize: "1.5vw", color: "white" }}>
        © 2016 - {year} YouROK Corp.
      </div>

      <a href="/privacy">Privacy Policy</a>
      <a href="/termsofuse">Terms of Use</a>
      <a href="/faqs">FAQs</a>
      <a href="/careers">Careers</a>
      <a href="/press">Press</a>
      <a href="./Contact" style={{ marginRight: "15px" }}>
        Contact
      </a>
    </footer>
  );
}

export default Footer;
