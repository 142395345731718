import React from "react";
import "../App.css";
import "./OurScience.css";

function OurScience() {
  return (
    <div className="container science">
      <div className="science_text">
        <h1>Science Behind YouROK</h1>
        <hr />
        <h4 className="text-center">
          EVIDENCE BASED RESEARCH SUPPORTING FUNCTIONALITY IN K’BRO
        </h4>
        <br></br>
        <br></br>

        <div className="col-md-12 ">
          {/* Start of correct formatting */}
          <h4>
            {" "}
            Why Emotional Resilience is important to mental health and why
            K’Bro?
          </h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                {" "}
                Overview of resiliency and emotional well-being.
              </p>
              <p className="summary-text" style={{fontSize: '20px'}}>
                Resilience, risk, mental health and well-being: associations and
                conceptual differences
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://link.springer.com/article/10.1007/s00787-016-0851-4"
                  style={{ marginLeft: 10 }}
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>

            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                {" "}
                Definition and importance of emotional resilience and
                well-being.
              </p>
              <p className="summary-text" style={{fontSize: '20px'}}>
                On being aware and accepting: A one-year longitudinal study into
                adolescent well-being
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.sciencedirect.com/science/article/abs/pii/S0140197110001417?via%3Dihub"
                  style={{ marginLeft: 10 }}
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>
          </ul>
          <h4>
            Why Emotional Resilience is important to mental health and why
            K’Bro?
          </h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Survey of research documenting benefits of emotional resilience
                on mental health.
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Interventions to build resilience among young people
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://yourokapps.com/public/download/interventions.pdf"
                  style={{ marginLeft: 10 }}
                  className="summary-text"
                >
                  View PDF
                </a>
              </p>
            </li>

            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Emotional well-being is more than the absence of mental health
                issues.
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Mental illness and well-being: the central importance of
                positive psychology and recovery approaches
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2835700/"
                  style={{ marginLeft: 10 }}
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>
          </ul>
        </div>
        {/* End of correct formatting. Everything below here will need to 
        be updated to match above. */}

        <div className="col-md-12">
          <hr />
          <h4>Benefits of Naming Emotions – Am I OK? Game</h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                How thinking about emotions can change your emotional state
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Putting Feelings Into Words Affect Labeling Disrupts Amygdala
                Activity in Response to Affective Stimuli
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3551284/"
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>
          </ul>
        </div>

        <div className="col-md-12">
          <hr />
          <h4>Benefits of Asking for help – Deal With It!</h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                The importance of confidentiality when sharing symptoms
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                What Teens Want: Barriers to Seeking Care for Depression
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3551284/"
                  className="summary-text"
                >
                  View Artcile
                </a>
              </p>
            </li>
          </ul>
        </div>

        <div className="col-md-12">
          <hr />
          <h4>Benefits of Speaking Up or Self advocating – Speak Up!</h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Progression of self-advocacy skill growth mirroring overall
                development.
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Development of the Self-Advocacy Measure for Youth
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://scholarcommons.usf.edu/cgi/viewcontent.cgi?referer=&httpsredir=1&article=6639&context=etd"
                  className="summary-text"
                >
                  View Article
                </a>
                  
              </p>
            </li>

            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Being self-determined is a developmental task that all young
                people have to confront and is pertinent to their whole-person
                development.
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                  Self-determination as a psychological and positive youth
                  development construct
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3353314/"
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>
          </ul>
        </div>

        <div className="col-md-12">
          <hr />
          <h4>Benefits of Practicing gratitude/mindfulness – What’s Up?</h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Association between gratitude and a sense of overall well being
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Gratitude and Well Being: The Benefits of Appreciation
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3010965/"
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
              
            </li>

            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Counting blessings was associated with enhanced self-reported
                gratitude, optimism, and life satisfaction
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Counting blessings in early adolescents: An experimental study
                of gratitude and subjective well- being
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://greatergood.berkeley.edu/pdfs/GratitudePDFs/3Froh-BlessingsEarlyAdolescence.pdf"
                  className="summary-text"
                >
                  View Article
              </a>
              </p>
            </li>
          </ul>
        </div>

        <div className="col-md-12">
          <hr />
          <h4>Social/Peer Support and why it matters – My Tribe</h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Digital use can be inventive and even beneficial</p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Teenagers and social networking – it might actually be good for
                them
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.theguardian.com/lifeandstyle/2013/oct/05/teens-social-networking-good-for-them"
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>

            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Youth peer support programs may help to engage young people in
                mental health services.
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Youth peer support in a mental health context
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cymh.ca/modules/ResourceHub/?id=9BFD4B3D-3798-4E5D-A4C5-CABB8014F8AB"
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>
          </ul>
        </div>

        <div className="col-md-12">
          <hr />
          <h4>
            Benefits to children and their parents of better parental engagement
            – K’Bro Parent Subscription
          </h4>
          <ul>
            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Digital use can be inventive and even beneficial</p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Parents' monitoring-relevant knowledge and adolescents'
                delinquent behavior: evidence of correlated developmental
                changes and reciprocal influences
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pubmed/12795388"
                  className="summary-text"
                >
                  View Article
                </a>
              </p>
            </li>

            <li>
              <p style={{ marginTop: 20, fontWeight: "600", fontSize: 20 }}>
                Technology/Apps and mental health – The upside to mobile
                apps/gaming
              </p>
              <p className="summary-text"  style={{fontSize: '20px'}}>
                Serious Games and Gamification for Mental Health: Current Status
                and Promising Directions
                <a
                  className="nav-link-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5222787/"
                  className="summary-text"
                >
                  {" "}
                  View Article 
                </a>
              </p>
              <p>
                
              </p>
              <p style={{ marginBottom: 75, fontSize: '20px'}}>
                Supports games being positively used for change and their
                potential
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OurScience;
