import React from "react";
import "../../App.css";
import "../About.css";

function Team() {
    return (

        <div className="about">
            <div className="container">
                <div className="row family-pic">
                    <div className="col-md-12">
                        <div
                            style={{ display: "block" }}
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <ol className="carousel-indicators">
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="0"
                                className="active"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="1"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="2"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="3"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="4"
                            ></li>
                            </ol>
                            <div className="carousel-inner">
                            <div className="carousel-item active">
                                <blockquote>
                                <h4>Patrina Mack</h4>
                                <div className="profile-circle">
                                    <img
                                    src="/images/patrinamack.png"
                                    alt="Woman with brown hair smiling in front of white background."
                                    />
                                </div>
                                <h5>
                                    CEO/Founder <br />
                                    Product/Market Fit Expert
                                </h5>
                                <p style={{ padding: "1rem", textAlign: "justify", fontSize: '20px'}}>
                                    Patrina Mack is CEO and founder of YouROK. She is a past
                                    board member of the Mental Research Institute where she
                                    helped reinvision the organization’s role from research to
                                    becoming a foundation. In 2017 she was honored as a Woman
                                    of Influence by the San Jose Business Journal. Prior to
                                    founding YouROK Corp., Patrina founded Vision & Execution
                                    in 1999. V&E served its clients as interim sales,
                                    marketing and product development support for global
                                    clients. Prior to that she led the SaaS product
                                    development for NetGravity launching globally through IPO
                                    until being acquired by Doubleclick. Before that she led
                                    new product initiatives domestically and internationally
                                    for PacTel Corporation through its spinoff and IPO to
                                    become AirTouch.
                                </p>
                                </blockquote>
                            </div>

                            <div className="carousel-item">
                                <blockquote>
                                <h4>Louis Monier</h4>
                                <div className="profile-circle">
                                    <img
                                    src="/images/louismonier.png"
                                    alt="Man with short goatee in gray shirt smiling in front of red background."
                                    />
                                </div>
                                <h5>
                                    Technical Advisor
                                    <br />
                                    Deep Learning Expert
                                </h5>
                                <p style={{ padding: "1rem", textAlign: "justify", fontSize: '20px' }}>
                                    Louis Monier was a founder of the Internet search engine
                                    AltaVista. After he left AltaVista, he worked at eBay and
                                    then at Google. He left Google in August 2007 to join
                                    Cuil, a search engine startup where he was Vice President
                                    of Products. He also was the co-founder and CTO of Qwiki
                                    with Doug Imbruce. Qwiki won the TechCrunch Disrupt Award
                                    in 2010 and was recently sold to Yahoo. Louis was the
                                    Chief Scientist of Proximic until July 2013, and then
                                    founded a health technology company, Kyron. He is
                                    currently Chief Scientist at Import.io and serves as
                                    Advisor, Investor and Deep Learning Faculty member at
                                    Holberton School in San Francisco. Monier received a Ph.D.
                                    in Mathematics and Computer Science from the University of
                                    Paris XI, France in 1980 and worked at Carnegie Mellon
                                    University, Xerox PARC, and DEC's Western Research
                                    Laboratory.
                                </p>
                                </blockquote>
                            </div>

                            <div className="carousel-item">
                                <blockquote>
                                <h4>Gregory Renard</h4>
                                <div className="profile-circle">
                                    <img
                                    src="/images/gregoryrenard.png"
                                    alt="Man wearing glasses and pinstriped collared shirt in front of a flowchart."
                                    />
                                </div>
                                <h5>
                                    Technical Advisor
                                    <br />
                                    Deep Learning Expert
                                </h5>
                                <p style={{ padding: "1rem", textAlign: "justify", fontSize: '20px' }}>
                                    Grégory Renard is CTO & Chief Visionary Officer at xBrain.
                                    Previously was CIO at Wygwam sarl, Usilink, Regional
                                    Director at Microsoft, and Professor at ISEN. Grégory
                                    began his career in 1996 in academic research around new
                                    technologies and created his first company in 1997 in the
                                    field of web development. Greg is an expert in artificial
                                    intelligence and big data working on unsupervised deep
                                    learning, bot, personal assistant, connected things and
                                    voice/semantic search for more than 15 years. He is
                                    currently a Deep Learning Faculty member at Holberton
                                    School in San Francisco. Through his professional
                                    activities in the various companies he created, his
                                    publications (books, blogs, etc.) and conferences, he is
                                    recognized as an expert on computer technologies
                                    (especially from Microsoft with the title of Most Valuable
                                    Professional since 2003 and as Regional Director since
                                    2006) then as a futurist and architect of innovations.
                                    Grégory continues to advise many large companies on their
                                    strategy and organization innovation. He is also a
                                    professional technical futurist speaker in different
                                    European universities and corporate events in France,
                                    Europe, and US.
                                </p>
                                </blockquote>
                            </div>

                            <div className="carousel-item">
                                <blockquote>
                                <h4>Aiden Arnold</h4>
                                <div className="profile-circle">
                                    <img
                                    src="/images/AA_Headshot.png"
                                    alt="Man in black and white photograph standing in front of white background, short beard, hint of a smile."
                                    />
                                </div>
                                <h5>
                                    Scientific Advisor
                                    <br />
                                    Cognitive Neuroscience
                                </h5>
                                <p style={{ padding: "1rem", textAlign: "justify", fontSize: '20px' }}>
                                    Aiden Arnold has a PhD in cognitive neuroscience from the
                                    University of Calgary and was formerly a visiting research
                                    scholar at the University of California, Davis. He has 10+
                                    years experience in research design, statistical analysis,
                                    and data visualization. The central theme of Aiden’s
                                    academic research is how the brain takes in sensory
                                    information, uses it to construct representations of the
                                    world, and informs decision making processes using these
                                    representations. During his PhD, I made numerous
                                    discoveries on how these processes occur through dynamic
                                    interactions of distributed neural networks by combining
                                    different aspects of graph theory and theoretical
                                    perspectives from cognitive psychology. In addition to
                                    being an Advisor to YouROK he also serves as Data Science
                                    Fellow at Springboard. Prior to this has been a co-founder
                                    for two startups: EmoGenius where he designed a
                                    neurotherapeutic game to train socially appropriate emotes
                                    in children with Autism Spectrum Disorder and YouFindIt
                                    where he designed a neurotherapeutic game to assess and
                                    train spatial skills in children.
                                </p>
                                </blockquote>
                            </div>

                            <div className="carousel-item">
                                <blockquote>
                                <h4>Wendy Renee Holthaus</h4>
                                <div className="profile-circle">
                                    <img
                                    src="/images/WRH_Headshot.png"
                                    alt="Woman with blond hair and black glasses, smiling."
                                    />
                                </div>
                                <h5>
                                    Scientific Advisor
                                    <br />
                                    Life Coach
                                </h5>
                                <p style={{ padding: "1rem", textAlign: "justify", fontSize: '20px' }}>
                                    Wendy Renee Holthaus is founder of Warrior Hearts
                                    Coaching. She completed her B.A. in Clinical Psychology at
                                    San Francisco State University and her M.A. in Counseling
                                    Psychology, Marriage and Family Therapy at Notre Dame de
                                    Namur. She worked in the field of social work for 20 years
                                    at San Andreas Regional Center, Santa Clara County and San
                                    Mateo County. Wendy is certified as a Life Coach by Martha
                                    Beck, certified in BARE coaching by Susan Hyatt, certified
                                    as an addictions treatment counselor (CATC4), received her
                                    certification in Positive Psychology based on the UPenn
                                    curriciulum, and has a certification in Childhood
                                    Education.
                                </p>
                                </blockquote>
                            </div>
                            </div>

                            <a
                            className="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                            >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                            </a>
                            <a
                            className="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                            >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )  
}

export default Team;
