import React from "react";
import "../../../App.css";
import "./KbroFeatures.css";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import "../../Dealwith.js";

function KbroFeatures() {
  return (
    <div className="features">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="features_text">
              <h1>
                How Users Benefit from
                <span style={{ fontSize: 45 }}> K'bro</span>
              </h1>
              <hr />
              <p style={{fontSize: '20px'}}>
                YouROK cultivates your emotional well-being so you can be happier 
                with who you are. Evidence-informed activities clinically proven 
                to build emotional resiliency have been gamified so it’s fun to do 
                things that are actually good for you. And when you play regularly 
                you feel better:  about you, your friends, your life!
              </p>
              <blockquote>
                <div
                  className="quote"
                  style={{ borderLeft: "2.5px solid green", padding: "5px" }}
                >
                  <cite style={{fontSize: '20px'}}>
                    "A game that lets you ask questions that you wouldn't talk to your friends or parents about."
                  </cite>
                </div>
                <div
                  className="quote"
                  style={{ borderLeft: "2.5px solid green", padding: "5px" }}
                >
                  <cite style={{fontSize: '20px'}}>
                    "Important app about communication and how I feel."
                  </cite>
                </div>
                <div
                  className="quote"
                  style={{ borderLeft: "2.5px solid green", padding: "5px" }}
                >
                  <cite style={{fontSize: '20px'}}>
                    "A good way to help me feel better or just try to get friends to beat my score."
                  </cite>
                </div>
              </blockquote>
              <p style={{fontSize: '20px'}}>
                Part game/part anonymous sharing/part knowledgebase, K’Bro is a safe 
                place for you to share your thoughts and emotions and get advice on how 
                to deal with life’s challenges while getting validation on how well you’re 
                doing through the “Am I OK?” index.
              </p>
              
            </div>
            
          </div>
          
          <div className="col-md-6 col-sm-12">
            <div className="features_img" style={{ paddingTop: "10px" }}>
              <img
                className="d-block w-100"
                src="images/features_img.png"
                alt="Practice Naming Your Emotions"
              />
              <Card.Footer
                style={{
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                Practice naming your emotions
              </Card.Footer>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div
              className="img_text"
              style={{ position: "flex", marginTop: 20 }}
            >
              <h1>Am I OK? Game</h1>
              <p style={{fontSize: '20px'}}>
                Deep learning using semantic search and natural language processing 
                of emotions expressed and issues raised via anonymous sharing will 
                produce a daily "Am I OK?" index score to let kids and the adults 
                who care for them know how well they're doing, alerting them when 
                there is cause for concern. 
              </p>
              <p style={{fontSize: '20px'}}>
                K’Bro captures four emotions out of 12 (six positive options and six 
                negative options) for four categories in a child’s life:  School, 
                Family Life, Social Life and Everything Else. This helps K’Bro pinpoint 
                which aspect of their life is where they’re having issues.  
              </p>
            </div>
          </div>
        </div>

        {/* 
        This deck needs a minor refactor. The <a> element needs 
        to wrap the entire card so clicking text or image will redirect 
        to appropriate page. Speak Up and What's Up pages have not yet 
        been built. Might want to start there.   
        */}
        <div className="deal_with">
          <CardDeck>
            <Card>
              <a href="../../dealwith">
              <Card.Body>
                <Card.Title>Deal With It!</Card.Title>
              </Card.Body>
              <Card.Img
                variant="top"
                src="images/dealwithhw.png"
                alt="Screen capture of Deal with it page on K Bro App"
              />
              <Card.Footer>
                Learn how to handle things on your own.
              </Card.Footer>
              </a>
            </Card>
            <Card>
              <a href="../../speakup">
                <Card.Body>
                  <Card.Title>Speak Up!</Card.Title>
                </Card.Body>
                <Card.Img
                  variant="top"
                  src="images/speak-up.png"
                  alt="Screen capture of Speak Up page on K Bro App"
                />
                <Card.Footer>
                  Practice advocating for <br /> yourself.
                </Card.Footer>
              </a>
            </Card>
            <Card>
              <a href="../../whatsup">
                <Card.Body>
                  <Card.Title>What's Up?</Card.Title>
                </Card.Body>
                <Card.Img
                  variant="top"
                  src="images/whats-up.png"
                  alt="Screen capture of Whats Up page on K Bro App"
                />
                <Card.Footer>
                  Practice gratitude on a daily <br /> basis.
                </Card.Footer>
              </a>
            </Card>
          </CardDeck>
        </div>
        {/* This is the end of the items that need to be refactored. */}
      </div>
    </div>
  );
}

export default KbroFeatures;
