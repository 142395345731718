import React from "react";
import "./Dealwith.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Dealwith() {
  return (
    <div className="dealwith">
      <div className="container">
        <div className="dealwith_text">
          <h1>Deal With It!</h1> 
          <hr />
          {/* This does not stack properly when resizing window. These overlap when near tablet size. Update bootstrap classes to resize automatically */}
          <div className='mid-section'>
            <div className='mid-child left-side'>
              <img
                src="/images/dealwithhw.png"
                alt="Screen capture of app, showing Deal With It header and sample entries."
              />
            </div>
            <div className='mid-child right-side'>
              
                K’Bro provides a knowledge base dedicated to adolescent topics
                to help users figure out to handle things on their own. If they
                don’t find the answer they’re looking for, they can ask the My
                Tribe community anonymously how other users have handled similar
                issues. Users get the chance to vote “good idea” or “bad idea” on
                feedback others provide and to block anyone whose answer seem
                inappropriate.
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dealwith;
