import React from "react";
import "../../../App.css";
import "./KbroUsers.css";
import { Card } from "react-bootstrap";

function KbroUsers() {
  return (
    <div className="users">
      <div className="container">
        <div className="users_text">
          <h1>K'Bro Users</h1>
          <hr />
          <p>
          YouROK’s engaging app K’Bro captures useful insights into student 
          well-being but more importantly provides low cost interventions to 
          help students improve their emotional resiliency. YouROK’s self-care 
          interventions reinforce social emotional literacy curriculum while 
          identifying who needs help beyond classroom instruction. Student care 
          and support teams can easily view student Am I OK? index scores, access 
          synthesized results, and get real time, targeted insights into individual 
          students as well as view school and district-wide trends. Data collected 
          is always confidential and secure, fully complying with HIPAA and FERPA standards.
          </p>

          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div
                className="users_img"
                style={{ marginBottom: "20px", marginTop: "10px" }}
              >
                <img
                  className="d-block w-100"
                  style={{ borderRadius: "15px" }}
                  src="images/teensphones.jpeg"
                  alt="Practice Naming Your Emotions"
                />
                <p className="text-center">
                  Ready to learn more about YouROK?
                  <a
                    style={{ fontSize: 20 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
                  >
                    {" Request a demo"}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <Card style={{ border: "none", marginLeft: "1rem" }}>
                <p>
                  Part game/part anonymous sharing/part knowledgebase, K’Bro is
                  a safe place for user to share their thoughts and emotions and
                  get advice on how to deal with life’s challenges while getting
                  validation on how well they’re doing through the “Am I OK?”
                  index.
                </p>
                <p>
                  Deep learning using semantic search and natural language
                  processing of emotions expressed and issues raised via
                  anonymous sharing will produce a daily “Am I OK?” index score
                  to let kids and their professionals know how well they’re
                  doing, alerting crisis resources when there is cause for
                  concern.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KbroUsers;
