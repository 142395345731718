import React from 'react';
import '../../App.css';
import Contact from '../Contact.js';

function ContactUs() {
    return (
        <>
            <Contact />
        </>
    );
}

export default ContactUs;
