import React from 'react';
import '../../App.css';
import About from '../About.js';

function AboutUs() {
    return (
        <>
           <About /> 
        </>
    )
}

export default AboutUs;
