/* Build me. Use Dealwith.js as a template. 
ATTENTION! When writing this note, Dealwith.js was not 
yet responsive, so use it for visual template only. 
Code is erroneous.*/ 
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Speakup() {
  return (
    <div className="dealwith">
      <div className="container">
        <div className="dealwith_text">
          <h1>SPEAK UP!</h1> 
          <hr />
          {/* This does not stack properly when resizing window. These overlap when near tablet size. Update bootstrap classes to resize automatically */}
          <div className='mid-section'>
            <div className='mid-child left-side'>
              <img
                src="/images/speak-up.png"
                alt="Screen capture of app, showing Deal With It header and sample entries."
              />
            </div>
            <div className='mid-child right-side'>
              
                K'Bro created "Speak Up!" to help users practice advocating for 
                themselves. In many ways, it is the letter you write to process your 
                emotions but never send. It is also the opportunity to provide positive 
                or negative feedback to someone as part of self-advocacy even with you 
                the parent! Users have the option of asking MY Tribe to provide 
                feedback on their reposnse by rating it "Helpful" or "Hurtful" to 
                ensure your child sends a productive message to accomplish their 
                self-advocacy goal.
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Speakup;
