import React from 'react';
import '../../App.css';
import OurScience from '../OurScience.js';

function Science() {
  return (
    <>
      <OurScience />
    </>
  );
}

export default Science;