import React from "react";
import "./TopNavRedux.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faAppStore,
  faGooglePlay,
} from "@fortawesome/free-brands-svg-icons";

function Sharing() {
  return (
    <div className="flex-parent">
      <div className="sharing text-center">
        <h4 className="text-center">Follow Us</h4>
      </div>
      <div className="sharing text-center">
        <a
          href="https://www.youtube.com/channel/UCFPq1GCb4CRq1WaIzVp515g"
          className="social"
        >
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a
          href="https://www.facebook.com/YouROKcorp/"
          className="social"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://twitter.com/kbrohappyapp" className="twitter social">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a
          href="https://www.instagram.com/kbroapp/?hl=en"
          className="social"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a
          href="https://www.linkedin.com/company/you-rok-corp/"
          className="social"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a
          href="https://apps.apple.com/us/app/kbro-emotional-resiliency-app/id1434638133"
          className="social social-store"
        >
          <FontAwesomeIcon icon={faAppStore} size="2x" /> 
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.yourok.kbro&hl=en_US&gl=US"
          className="social social-store"
        >
          <FontAwesomeIcon icon={faGooglePlay} size="2x" /> 
        </a>
      </div>
    </div>
  );
}
 
export default Sharing;
