import React from "react";
import "../../../App.css";
import "./Payers.css";

function PayersPage() {
  return (
    <div className="payers">
      <div className="container">
        <div className="payers_text">
          <h1>Health Payers</h1>
          <hr />
          <div className="row">
            <div className="payers_img col-lg-6 col-sm-12">
              <img
                className="d-block w-100"
                style={{ borderRadius: "15px" }}
                src="images/payer.jpg"
                alt="Practice Naming Your Emotions"
              />
              <p className="text-center"  style={{fontSize: '20px'}}>
                Ready to learn more about YouROK?
                <a
                  style={{ fontSize: 20 }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
                >
                  {" Request a demo"}
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-sm-12" style={{ padding: "0px" }}>
              <p style={{fontSize: '20px'}}>
              Suicide is increasing fastest among the adolescent population running up 
              Emergency Department costs responding to suicide attempts. A 2019 CDC 
              report found that there has been a marked increase in adolescent mental 
              health related emergency room visits, increasing by 24% in the 5-11year 
              old population and 31% among the 12-17 year old population. Value-based 
              care often penalizes hospitals who treat suicide patients who have not yet 
              established a relationship with a mental health provider.
              </p>
            </div>
            <div style={{ padding: "0px" }}>
              <p style={{fontSize: '20px'}}>
                YouROK enables payers to reduce the cost of care through prevention 
                and early detection. Our solution captures hard to get data to identify 
                when children need behavioral health intervention. The YouROK solution 
                enables payers to track adolescent mental wellness and by extension gauge 
                family and life functioning through its Enterprise Portal. For children 
                being treated therapeutically it is extremely difficult to measure progress 
                and know when to stop therapy or when to change treatment. K’Bro provides 
                unfiltered user insights to determine if therapeutic invention is needed and 
                for therapists to monitor and adjust treatment.  
              </p>
            </div>
            <div style={{ padding: "0px" }}>
              <p style={{fontSize: '20px'}}>
              <div style={{ padding: "0px" }}>
              <p style={{fontSize: '20px'}}>
                Health plans, employers, and managed behavioral health organizations use 
                YouROK to address critical gaps in care and better execute on 
                value-based arrangements. Payers utilize YouROK’s dashboards to expand 
                behavioral health access, improve mental health outcomes and complement 
                face-to-face therapy options at a fraction of the cost.  
              </p>
            </div>  
              </p>
            </div>
          </div>

          <blockquote>
            <div
              className="quote"
              style={{ borderLeft: "2.5px solid green", padding: "5px" }}
            >
              <p style={{fontSize: '20px'}}>
                "YouROK was selected as the American Psychiatry Impact Award Winner because 
                of its innovative approach to reaching kids and helping them identify and 
                share their emotions at an early stage. Building emotional resilience is a 
                foundation of disease prevention and YouROK has built a creative, engaging, 
                and scalable game to bring these skills to kids."
              </p>
              <cite style={{fontSize: '20px'}}>
              Nina Vasan, MD, MBA, Chair of the APA Committee on Innovation, Founder of the 
              Psychiatry Innovation Lab, and Director of Brainstorm, the Stanford Lab for Brain 
              Health Innovation and Entrepreneurship at Stanford University School of Medicine.
              </cite>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default PayersPage;
