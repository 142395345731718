import React from "react";
import "../../../App.css";
import "./Solutions.css";

function SolutionsPage() {
  return (
    <div className="solutions">
      <div className="container">
        <div className="solutions_text">
          <h1>Why Choose YouROK?</h1>
          <hr />
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="solutions_txt">
                <p style={{fontSize: '20px'}}>
                  Adolescent mental health is a growing <span style={{fontSize: '20px'}}>epidemic.</span>
                </p>
                <p style={{fontSize: '20px'}}>
                  Thirteen percent of children ages 8 to 15 have had a diagnosable mental
                  disorder within the previous year, 20% of youth ages 13-18
                  have a severe mental disorder, and 46% will experience some
                  form of mental health disorder in their lifetime.
                </p>
                <p style={{fontSize: '20px'}}>
                  This means that 1 in 5 school age children are in need of
                  mental health support and intervention in a system lacking the
                  capacity to deliver these services. Only 7.4 percent of
                  adolescents report visiting with a mental health professional
                  over the course of a year.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="solutions_img">
                <img
                  className="d-block w-100"
                  style={{ borderRadius: "15px" }}
                  src="images/bullying.jpg"
                  alt="Kids"
                />
                <p className="text-center" style={{fontSize: '20px'}}>
                  Ready to learn more about YouROK?
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
                  >
                    {" Request a demo"}
                  </a>
                </p>
              </div>
            </div>

            <p style={{fontSize: '20px'}}>
              Untreated children unduly tax school systems that lack the
              resources to intervene. The growing impact of pediatric mental
              health disorders is driving up costs for children’s hospitals and
              health care delivery systems.
            </p>
            <p style={{fontSize: '20px'}}>
              Emergency rooms are taxed with a growth in hospitalizations due to
              suicide. Suicide is the third leading cause of death among
              adolescents in the United States; these rates more than doubled
              from 2008 to 2015. For those aged 15 to 24 suicide is the
              <span> second leading cause of death.</span>
            </p>
          </div>
          <p style={{ fontWeight: "bold", marginLeft: "-15px", fontSize: '20px' }}>
            YouROK addresses these challenges through its emotional resiliency
            app K’Bro which provides the insights those who care for adolescents
            need to ensure their emotional well-being.
          </p>
          <blockquote>
            <div
              className="quote"
              style={{ borderLeft: "2.5px solid green", padding: "5px"}}
            >
              <p style={{fontSize: '20px'}}>
                "K'Bro helps me get through tough times, and keeps parents off
                my back with their daily check-ins."
              </p>
              <cite style={{fontSize: '20px'}}>15 year old user</cite>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default SolutionsPage;
