import React from "react";
import "./Navbar.css";
import * as ReactBootStrap from "react-bootstrap";

function Navbar() {
  return (
    <ReactBootStrap.Navbar
      style={{ zIndex: 2 }}
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
    >
      <ReactBootStrap.Navbar.Brand href="/">
        <img src="images/logo.png" className="app-logo" alt="YouROK Logo" />
      </ReactBootStrap.Navbar.Brand>
      <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
        <ReactBootStrap.Nav className="mr-auto"></ReactBootStrap.Nav>
        <ReactBootStrap.Nav>
          <ReactBootStrap.Nav.Link
            href="/"
            style={{ fontSize: 20 }}>
            Home
          </ReactBootStrap.Nav.Link>

          <ReactBootStrap.NavDropdown
            title="Solutions"
            id="collasible-nav-dropdown"
          >
            <ReactBootStrap.NavDropdown.Item
              href="/solutions"
              style={{ fontSize: 20 }}
            >
              Why YourOK
            </ReactBootStrap.NavDropdown.Item>
            <ReactBootStrap.NavDropdown.Item
              href="/payers"
              style={{ fontSize: 20 }}
            >
              Payers
            </ReactBootStrap.NavDropdown.Item>
            <ReactBootStrap.NavDropdown.Item
              href="/providers"
              style={{ fontSize: 20 }}
            >
              Providers
            </ReactBootStrap.NavDropdown.Item>
            <ReactBootStrap.NavDropdown.Item
              href="/schools"
              style={{ fontSize: 20 }}
            >
              Schools
            </ReactBootStrap.NavDropdown.Item>
            <ReactBootStrap.NavDropdown.Item
              href="/parents"
              style={{ fontSize: 20 }}
            >
              Parents
            </ReactBootStrap.NavDropdown.Item>
          </ReactBootStrap.NavDropdown>

          <ReactBootStrap.Nav.Link
            href="/kbrofeatures"
            style={{ fontSize: 20 }}
          >
            K'Bro Features
          </ReactBootStrap.Nav.Link>
          <ReactBootStrap.Nav.Link href="/science" style={{ fontSize: 20 }}>
            Science
          </ReactBootStrap.Nav.Link>
          <ReactBootStrap.NavDropdown
            title="About"
            id="collasible-nav-dropdown"
          >
            <ReactBootStrap.NavDropdown.Item
              href="/about"
              style={{ fontSize: 20 }}
            >
              Our Mission
            </ReactBootStrap.NavDropdown.Item>
            <ReactBootStrap.NavDropdown.Item
              href="/team"
              style={{ fontSize: 20 }}
            >
              Our Team
            </ReactBootStrap.NavDropdown.Item>
          </ReactBootStrap.NavDropdown>
          <ReactBootStrap.Nav.Link
            href="https://go.kbro.io/parent-portal"
            style={{ fontSize: 20 }}

          >
            Parent Login
          </ReactBootStrap.Nav.Link>
        </ReactBootStrap.Nav>
      </ReactBootStrap.Navbar.Collapse>
    </ReactBootStrap.Navbar>
  );
}

export default Navbar;
