import React from "react";
import "../App.css";
import "./About.css";

function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="about_text">
          <h1 className="text-center">Our Mission</h1>
          <hr />
          <div className="row">
            <p className="black-text" style={{fontSize: '20px'}}>
              YouROK was founded from a deep passion to challenge the status quo
              when it comes to adolescent mental health. No longer children but
              not yet adults, adolescents are the most underserved segment of
              the population. It’s no surprise that suicide is the 2nd leading
              cause of death for this age group. Mental health issues continue
              to rise sharply with a 37% increase in the prevalence of major
              depressive episodes among teens. Sadly, there has been no
              corresponding increase in treatment.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12" style={{ padding: "0px" }}>
              <p className="black-text" style={{fontSize: '20px'}}>
                There are many barriers to needed mental health services, such
                as peer stigma, fear of negative reactions from family members,
                underutilized school and community health services, lack of
                providers, cost and transportation constraints.
              </p>
              <p className="black-text" style={{fontSize: '20px'}}>
                Knowing that there must be a way to overcome these obstacles, we
                set out to help those we love… and the more than 50 million
                children who make up the statistic that 75% of mental health
                issues emerge by age 24.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 demo-announcement">
              <div className="parents_img">
                <img
                  className="d-block w-100"
                  style={{ borderRadius: "15px", marginLeft: -10 }}
                  src="images/family-of-four.png"
                  alt="Kids"
                />
                <p className="text-center black-text" style={{fontSize: '20px'}}>
                  Ready to learn more about YouROK?
                  <a
                    style={{ fontSize: 20 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
                  >
                    {" Request a demo"}
                  </a>
                </p>
              </div>
            </div>
            <p className="black-text"  style={{fontSize: '20px'}}>
              To make our vision real, we leveraged our extensive product and
              design expertise, adolescent mental health insights, and
              surrounded ourselves with brilliant game designers and advisors.
              Together, we created YouROK to give adolescents a stigma free
              resource to help create the emotional resiliency to withstand the
              challenges of becoming an adult. And, we provide the professionals
              who care for them real-time insights for risk mitigation to ensure
              that every teen survives adolescence to become an emotionally
              healthy adult.
            </p>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default About;
