/* Build me. Use Dealwith.js as a template. 
ATTENTION! When writing this note, Dealwith.js was not 
yet responsive, so use it for visual template only. 
Code is erroneous.*/ 
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function WhatsUp() {
  return (
    <div className="dealwith">
      <div className="container">
        <div className="dealwith_text">
          <h1>WHAT'S UP!</h1> 
          <hr />
          {/* This does not stack properly when resizing window. These overlap when near tablet size. Update bootstrap classes to resize automatically */}
          <div className='mid-section'>
            <div className='mid-child left-side'>
              <img
                src="/images/whats-up.png"
                alt="Screen capture of app, showing Deal With It header and sample entries."
              />
            </div>
            <div className='mid-child right-side'>
              
                What's Up? is where users get to practice gratitude on a daily basis. 
                Each day they will be asked three questions to highlight what is 
                going right in their life. If they have a "not so OK day" on the index, 
                they will be reminded of all the stuff that is going right to help give 
                them the emotional boost they need to start to feel better. 
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatsUp;