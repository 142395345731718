import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="contact_text">
          <h1>Contact Us</h1>
          <hr />
          <div className="row">
            <div className="left-bottom ">
              <iframe
                title="Contact Us"
                className="iframe"
                style={{
                  marginTop: "25px",
                  width: "100vw",
                  height: "190vh",
                  borderStyle: "none",
                }}
                src="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
