import React, { Component } from "react";
import "../App.css";
import "./HeroSection.css";
import Carousel from "react-bootstrap/Carousel";

export class HeroSection extends Component {
  render() {
    return (
      <Carousel
        className="container-fluid"
        style={{ margin: "0px", padding: "0px" }}
      >
        <Carousel.Item
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <img
            className="d-block w-100"
            src={"images/teenhealth-opaque.png"}
            alt="Medical staff with chart discussing information with patient"
          />

          <Carousel.Caption
            className="carousel-caption d-md-block"
            style={{
              textShadow: "2px 2px rgb(136, 128, 128)",
              zIndex: 0,
              transform: "translateY(12%)",
            }}
          >
            <h3 style={{ fontSize: "8vw", color: "rgb(255, 136, 0)" }}>
              PROVIDERS
            </h3>
            <h6 style={{ fontSize: "8vw" }}>WANT TO KNOW</h6>
            <hr className="carousel-hr"></hr>
            <p style={{ fontSize: "3vw" }}>
              How do I know which of my patients is most at risk?
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item style={{ height: "100%", width: "100%" }}>
          <img
            className="d-block w-100"
            src={"images/schools-opaque.png"}
            alt="Seven people sitting in a circle, main focus on a smiling teacher speaking with a college age student."
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px rgb(136, 128, 128)",
              zIndex: 0,
              transform: "translateY(12%)",
            }}
          >
            <h3 style={{ fontSize: "8vw", color: "rgb(255, 136, 0)" }}>
              SCHOOLS
            </h3>
            <h2 style={{ fontSize: "8vw" }}>WANT TO KNOW</h2>
            <hr className="carousel-hr"></hr>
            <p style={{ fontSize: "3vw" }}>
              How do we increase the emotional health of our campus?
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item style={{ height: "100%", width: "100%" }}>
          <img
            className="d-block w-100"
            src={"images/payers-opaque.png"}
            alt="Person reviewing visual data on a laptop screen."
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px rgb(136, 128, 128)",
              zIndex: 0,
              transform: "translateY(12%)",
            }}
          >
            <h3 style={{ fontSize: "8vw", color: "rgb(255, 136, 0)" }}>
              PAYERS
            </h3>
            <h2 style={{ fontSize: "8vw" }}>WANT TO KNOW</h2>
            <hr className="carousel-hr"></hr>
            <p style={{ fontSize: "3vw" }}>
              How can I offer prevention solutions to reduce the cost of
              adolescent mental health?
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item style={{ height: "100%", width: "100%" }}>
          <img
            className="d-block w-100"
            src={"images/father-daughter-opaque.png"}
            alt="Smiling parent with child, looking at videogame controller."
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px rgb(136, 128, 128)",
              zIndex: 0,
              transform: "translateY(12%)",
            }}
          >
            <h3 style={{ fontSize: "8vw", color: "rgb(255, 136, 0)" }}>
              PARENTS
            </h3>
            <h2 style={{ fontSize: "8vw" }}>WANT TO KNOW</h2>
            <hr className="carousel-hr"></hr>
            <p style={{ fontSize: "3vw" }}>
              Should I be worried about my child?
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default HeroSection;
