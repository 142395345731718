import React from "react";
import "../../../App.css";
import "./Providers.css";

function ProvidersPage() {
  return (
    <div className="providers">
      <div className="container">
        <div className="providers_text">
          <h1>Health Providers</h1>
          <hr />
          <div className="row">
            <p style={{fontSize: '20px'}}>
              The average visit with a pediatrician is 15 minutes leaving little time to delve 
              into issues beyond physical health. Only 1 in 3 pediatricians report that they 
              have sufficient training to diagnose and treat children with mental disorders, 
              yet they are frequently the first to be contacted when there are concerns given 
              the extreme shortage of mental health providers. The average wait time for a therapy 
              appointment is 7 weeks assuming there are even providers available given that 50% 
              of counties lack therapists. The reduced availability of talk therapists has 
              resulted in a six-fold national increase in office-based visits that included 
              prescription of antipsychotic medications yet there is limited tracking of outcomes 
              to determine efficacy.
            </p>
          </div>
          <div className="row">
            <p style={{fontSize: '20px'}}>
              And help is often needed during non-business hours.  When you do not know the 
              risk level the child is experiencing you have no idea if your patient will become 
              a statistic.   More than 4500 children aged 10-24 die by suicide with another 157,000 
              being treated in ERs across the country for self-inflicted injuries.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12" style={{ padding: "0px" }}>
              <p style={{fontSize: '20px'}}>
                Providers use YouROK to help risk stratify their patient population. YouROK’s proprietary 
                algorithms identify who is at highest risk for providers to prioritize care. 
                YouROK enables healthcare providers to increase mental health services without 
                adding staff while more quickly identifying who is most at risk. Our solution 
                captures hard to get data to identify when children need behavioral health 
                intervention. The YouROK solution enables providers to track adolescent mental 
                wellness and by extension gauge family and life functioning through its Enterprise Portal. 
                For children being treated therapeutically it is extremely difficult to measure 
                progress and know when to stop therapy or when to change treatment. K’Bro provides unfiltered 
                user insights to determine if therapeutic invention is needed and for therapists to monitor 
                and adjust treatment.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 demo-announcement">
              <div className="parents_img">
                <img
                  className="d-block w-100"
                  style={{ borderRadius: "15px" }}
                  src="images/counseling.jpeg"
                  alt="Practice Naming Your Emotions"
                />
                <p class="text-center" style={{fontSize: '20px'}}>
                  Ready to learn more about YouROK?
                  <a
                    style={{ fontSize: 20 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/19DxX8g2DRYudA8WDBtPmbw1nr57"
                  >
                    {" Request a demo"}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <blockquote>
            <div
              className="quote"
              style={{ borderLeft: "2.5px solid green", padding: "5px" }}
            >
              <p style={{fontSize: '20px'}}>
                "YouROK has designed a tool that will definitely help so many 
                youth in the form of their emotional resiliency app K’Bro. It is 
                clearly informed by youth and supported by professionals from multiple 
                disciplines to deliver evidenced-based practices in a fun, engaging way."
              </p>
              <cite style={{fontSize: '20px'}}>
                Laura J. Champion, LMFT, MBA, Consultant and Former Executive Director, 
                Uplift Family Services
              </cite>
              
            </div>
          </blockquote>

          <blockquote>
            <div
              className="quote"
              style={{ borderLeft: "2.5px solid green", padding: "5px" }}
            >
              <p style={{fontSize: '20px'}}>
                "K'Bro is a mindful breath of fresh air in the gaming world. The
                application blends social networking, an interactive community,
                gaming and AI with measurable CBT techniques. The app is a
                cost-effective pathway to therapy for adolescents and young
                adults."
              </p>
              <cite style={{fontSize: '20px'}}>
                Dave Solsberg, MD PC, Neuroradiologist/Inventor/Entrepreneur
              </cite>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default ProvidersPage;
